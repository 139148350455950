import ActiveLink, { ActiveLinkProps } from "components/Shared/ActiveLink";
import TooltipIconButton, {
  TooltipIconButtonProps,
} from "components/Shared/TooltipIconButton";

type Props = TooltipIconButtonProps & ActiveLinkProps;

export default function TooltipIconLinkButton({ ...rest }: Props) {
  return (
    <TooltipIconButton
      as={ActiveLink}
      tooltipProps={{ placement: "bottom", openDelay: 500 }}
      variant="ghost"
      borderRadius="none"
      color="rgba(255, 255, 255, .5)"
      _hover={{
        color: "white",
        backgroundColor: "rgba(255, 255, 255, .1)",
      }}
      _activeLink={{ backgroundColor: "magenta.dark", color: "white" }}
      px={4}
      fontSize="lg"
      {...rest}
    />
  );
}
