import React, { useState, useEffect, ReactNode } from "react";
import { useRouter } from "next/router";
import { BoxProps } from "@chakra-ui/react";
import { RestrictedModulePathProvider } from "utils/RestrictedModulePath";
import BrandModuleAccessGate from "utils/BrandModuleAccessGate";
import PageLoadingSpinner from "components/Shared/PageLoadingSpinner";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import BaseContentContainer from "./BaseContentContainer";
import useAppAlerts from "./useAppAlerts";
import { CONTENT_FULL_HEIGHT, SIDEBAR_WIDTH } from "./constants";
import { useIsAnalyticsLayoutPath } from "./brandLayoutUtils";

export type LayoutProps = Partial<BoxProps> & {
  children: ReactNode;
  isFullHeight?: boolean;
  isSidebarHidden?: boolean;
};

export default function Layout({
  children,
  isFullHeight,
  isSidebarHidden: isSidebarHiddenProp = false,
  ...rest
}: LayoutProps) {
  const { pathname } = useRouter();
  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState(false);

  const isAnalyticsLayout = useIsAnalyticsLayoutPath();

  const isSidebarHidden = isSidebarHiddenProp || isAnalyticsLayout;

  const handleShowOrHideSidebar = () => {
    setIsMobileSideBarOpen(!isMobileSideBarOpen);
  };

  useEffect(() => {
    setIsMobileSideBarOpen(false);
  }, [pathname]);

  useAppAlerts();

  return (
    <>
      <NavBar
        handleShowOrHideSidebar={handleShowOrHideSidebar}
        isMobileSideBarOpen={isMobileSideBarOpen}
        isMobileSideBarHidden={isSidebarHidden}
      />
      <SideBar
        isMobileSideBarOpen={isMobileSideBarOpen}
        isHidden={isSidebarHidden}
        display={isSidebarHidden === undefined ? "none" : undefined}
      />
      <BaseContentContainer
        marginLeft={{ base: 0, md: isSidebarHidden ? 0 : SIDEBAR_WIDTH }}
        isFullHeight={isFullHeight}
        isWithNavBar
        display={isSidebarHidden === undefined ? "none" : undefined}
        {...rest}
      >
        <RestrictedModulePathProvider>
          <BrandModuleAccessGate>{children}</BrandModuleAccessGate>
        </RestrictedModulePathProvider>
      </BaseContentContainer>

      {isSidebarHidden === undefined && (
        <PageLoadingSpinner height={CONTENT_FULL_HEIGHT} />
      )}
    </>
  );
}

export const getLayout = (page: React.ReactElement) => <Layout>{page}</Layout>;
