import { ReactNode } from "react";
import { Badge, Box } from "@chakra-ui/react";
import Link, { LinkProps } from "components/Shared/Link";
import { brandRetailerPage } from "utils/links";
import { FragmentType, getFragmentData, gql } from "__generated__/gql";

const fragment = gql(/* GraphQL */ `
  fragment CommentRelatedItem on Comment {
    id
    brandChainRetailerComment {
      commentId
      brandChainRetailer {
        id
        chainRetailer {
          id
          name
        }
      }
    }
  }
`);

type RelatedItemLinkProps = Omit<LinkProps, "children"> & {
  relatedName: ReactNode;
  relatedType: ReactNode;
};

function RelatedItemLink({
  relatedName,
  relatedType,
  ...rest
}: RelatedItemLinkProps) {
  return (
    <Badge
      as={Link}
      variant="outline"
      colorScheme="lightPinkButton"
      isTruncated
      minW={0}
      fontWeight={500}
      flex="0 1 auto"
      textTransform="none"
      {...rest}
    >
      <Box as="span" fontWeight={300}>
        {relatedType}:
      </Box>{" "}
      {relatedName}
    </Badge>
  );
}

type Props = {
  data: FragmentType<typeof fragment>;
};

export default function CommentRelatedItem({ data }: Props) {
  const comment = getFragmentData(fragment, data);

  if (comment.brandChainRetailerComment) {
    const bcr = comment.brandChainRetailerComment.brandChainRetailer;
    const cr = bcr?.chainRetailer;
    if (!bcr || !cr) return null;
    return (
      <RelatedItemLink
        href={brandRetailerPage(bcr.id)}
        relatedName={cr.name}
        relatedType="Retailer"
      />
    );
  }

  return null;
}
