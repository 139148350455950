import { ReactNode, useContext, useState } from "react";
import { Box, Button, Flex, ListItem } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import MarkdownText from "components/Shared/MarkdownText";
import UserChip from "components/Shared/UserChip";
import { FragmentType, getFragmentData, gql } from "__generated__/gql";
import RelativeTime from "./RelativeTime";
import { commentContext } from "./ListContainer";
import EditComment from "./Edit";
import CommentMenu, { DeleteUpdateFunction } from "./Menu";

const fragment = gql(/* GraphQL */ `
  fragment CommentDisplay on Comment {
    id
    body
    createdAt
    updatedAt
    user {
      id
      ...UserChipComponent
    }
    ...CommentEdit
  }
`);

type Props = {
  data: FragmentType<typeof fragment>;
  deleteUpdate?: DeleteUpdateFunction;
  headerRight?: ReactNode;
};

export default function Comment({ data, deleteUpdate, headerRight }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const context = useContext(commentContext);

  const comment = getFragmentData(fragment, data);

  const isEditable = !!context.userId && comment.user?.id === context.userId;

  return (
    <ListItem
      px={2}
      py={4}
      borderTopWidth={1}
      _first={{ borderTopWidth: 0 }}
      sx={{
        ".comment-list-container[data-is-reverse=true] &": {
          _first: { borderTopWidth: 1 },
          _last: { borderTopWidth: 0 },
        },
      }}
    >
      <Flex mb={4} gap={3} alignItems="flex-start">
        <UserChip user={comment.user} minW="7.5em" isEmailLinked />

        <Flex
          gap={1}
          alignItems="center"
          justifyContent="flex-end"
          ml="auto"
          minW="6em"
        >
          {headerRight}
          {isEditing ? (
            <Button
              variant="outline"
              size="xs"
              fontSize=".65em"
              color="gray.600"
              leftIcon={<CloseIcon boxSize=".7em" />}
              onClick={() => setIsEditing(false)}
              ml="auto"
            >
              Cancel
            </Button>
          ) : (
            <Flex alignItems="center" justifyContent="flex-end" minW="3.5em">
              <Box fontSize=".8em" color="gray.500" textAlign="right">
                <RelativeTime datetime={comment.createdAt} />
              </Box>

              {isEditable && (
                <CommentMenu
                  commentId={comment.id}
                  deleteUpdate={deleteUpdate}
                  onEdit={() => setIsEditing(true)}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      {isEditing ? (
        <EditComment
          data={comment}
          onCloseEditing={() => setIsEditing(false)}
        />
      ) : (
        <MarkdownText
          markdown={comment.body}
          sx={{
            ".md-table-container": { width: "100%", overflowX: "auto" },
          }}
        />
      )}
    </ListItem>
  );
}
